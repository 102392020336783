// MAIN.JS
jQuery(function($) {

	/**********************************************/
	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
    if ($.fn.mask) {
        $("input[type='tel']").mask("(999) 999-9999? #99999");
        $("input[type='text'].postalcode").mask("a9a 9a9", {
            completed:function () {
                this.val(this.val().toUpperCase());
            }
        });
    }

	/**********************************************/

	//MATERIALIZE  CAROUSEL
	/*if ($.fn.carousel) {
	var instance = $('.carousel').carousel({
			 shift: 10,
			 padding: 20,
			 numVisible: 3,
			 duration: 200, // ms
			 dist: -100, // zoom scale TODO: make this more intuitive as an option
			 fullWidth: false, // Change to full width styles
			 indicators: false, // Toggle indicators
			 noWrap: false, // Don't wrap around and cycle through items.
			 onCycleTo: null, // Callback for when a new slide is cycled to.
	});
	autoplay();
function autoplay() {
    $('.carousel').carousel('next');
    setTimeout(autoplay, 3000);
}
		//instance.next(1).prev(1);

		// $('#carousel-next').click(function() {
		//     $('.carousel').carousel('next');
		// });
		//
		// $('#carousel-prev').click(function() {
		//     $('.carousel').carousel('prev');
		// });
	}


	/**********************************************/

	// JQUERY.MAGNIFIC-POPUP
	if ($.fn.magnificPopup) {
		// image
		$('.image-popup').magnificPopup({type:'image'});
        $('.image-group-popup').each(function() {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        });
		// iframe
		$('.iframe-popup').magnificPopup({type:'iframe'});
        $('.iframe-group-popup').each(function() {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'iframe',
                gallery: {
                    enabled: true
                }
            });
        });
	$('.zoom-gallery').magnificPopup({
			delegate: 'a',
			type: 'image',
			closeOnContentClick: false,
			closeBtnInside: false,
			overflowY: 'scroll',
			mainClass: 'mfp-with-zoom mfp-img-mobile',
			image: {
			verticalFit: true,
				// titleSrc: function(item) {
				// 	return item.el.attr('title') + ' &middot; <a class="image-source-link" href="'+item.el.attr('data-source')+'" target="_blank">image source</a>';
				// }
			},
			gallery: {
				enabled: true
			},
			zoom: {
				enabled: true,
				duration: 300, // don't foget to change the duration also in CSS
				opener: function(element) {
					return element.find('img');
				}
			},
			fixedContentPos: false,
			callbacks: {
				open: function() {
					jQuery('body').addClass('noscroll');
				},
				close: function() {
					jQuery('body').removeClass('noscroll');
				}
			}
		});
		$('.gallery').each(function () {
				$(this).magnificPopup({
						delegate: 'div a',
						type: 'image',
						gallery: {
								enabled: true,
								navigateByImgClick: true
						},
						fixedContentPos: false
				});
		});

	}

	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}

	/**********************************************/

	// OWL.CAROUSEL2
   $('.main-content .owl-carousel').owlCarousel({
    loop: true,
    margin: 30,
    autoplay: true,
		autoplayTimeout: 3000,
		center: true,
		slideBy: 2,
		fluidSpeed: 250,
    nav: true,
    navText: [
        '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        '<i class="fa fa-angle-right" aria-hidden="true"></i>'
    ],
    navContainer: '.main-content .custom-nav',
    responsive:{
		0:{
		    items:1
		},
		480:{
		    items:1
		},
		768:{
			items:1
		},
		1920:{
		     items:1
		}
	}

	});


	/************************************************************************/
	/* menu hide and show when scrolled	and other scroll functions			*/
	/************************************************************************/
    let didScroll;
    let lastScrollTop = 0;
    const delta = 10;
    let navbarHeight = $(".menu-navbar").outerHeight();
    // function to store scroll amount in <html data-scroll="">
	const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY;
    }

    // Set flag to see if window has scrolled
	$(window).scroll( function(event) {
		didScroll = true;
        storeScroll();
	});

    // Calls all scroll dependent scripts
    // And cut on processing time by the browser
    // by reading stats only x milliseconds (250)
	setInterval(function() {
		if (didScroll) {
            menuHasScrolled();
            toggleVisibleClassOnScroll();
            didScroll = false;
		}
	}, 250);

	// if element has "visibilityCheck" it will have "isVisible" class when
    // visible
	function toggleVisibleClassOnScroll() {
        $('.visibilityCheck').is( function () {
            let viewing = $(this).visible(true);
            if (viewing) {
                $(this).addClass('isVisible');
            } else {
                $(this).removeClass('isVisible');
            }
        });
        $('.completeVisibilityCheck').is( function () {
            let viewing = $(this).visible();
            if (viewing) {
                $(this).addClass('isCompletelyVisible');
            } else {
                $(this).removeClass('isCompletelyVisible');
            }
        });
    }

    // Adds the nav-invisible and nav-visible to element with class menu-desktop
	function menuHasScrolled() {
        let st = parseInt(document.documentElement.dataset.scroll);

        // Make sure they scroll more than delta
	    if ( Math.abs(lastScrollTop - st) <= delta )
	        return;
	    // If they scrolled down and are past the navbar, add class .nav-invisible.
	    // This is necessary so you never see what is "behind" the navbar.
        //console.log('st: ' + st);
        //console.log('lastScrollTop: ' + lastScrollTop);

        if ( st < parseInt(lastScrollTop) ) {
            // Scroll Up
            $('.menu-navbar').addClass('nav-visible').removeClass('nav-invisible');
        }
	    if (st > lastScrollTop && st > navbarHeight) {
	        // Scroll Down
	        $('.menu-navbar').removeClass('nav-visible').addClass('nav-invisible');
	    }

		lastScrollTop = st;

		if (st > 0) {
			$('.module-menu').addClass('scrolled');
		}
		else {
			$('.module-menu').removeClass('scrolled');
		}
	}

	// write the scroll position on page load
    $(document).ready(function () {
        storeScroll();
    });


	/************************************************/
	/* Ferme le popup si cliquer	 				*/
	/************************************************/
	$(".popup").click(function(e) {
	    $(".popup").fadeOut();
	});


	/************************************************/
	/* Montre les messages si présents 				*/
	/************************************************/
	$(document).ready(function () {
        if($('.errors').length) {
            $('html, body').animate({
                scrollTop: $('.errors').offset().top + 200
            }, 'slow');
        }
    });

    $(document).ready(function () {
		if($('.messages').length) {
			$('html, body').animate({
				scrollTop: $('.messages').offset().top + 200
			}, 'slow');
		}
    });

});
